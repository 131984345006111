import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { call, put, select } from 'redux-saga/effects';
import selectors from '../selectors';
import entryActions from '../entry-actions';
import ArchiveModal from '../components/ArchiveModal';



const mapStateToProps = (state) => {
  const project = selectors.selectCurrentProject(state);
  const lists = selectors.selectArchivedLists(state);
  const boards = selectors.selectArchivedBoards(state);
  const cards = selectors.selectArchivedCards(state);
  for(let board of boards) {
    board.path = [];
    board.path.push(project.name);
    if(board.archiveUser) {
      board.aUser = selectors.selectUserById(state, board.archiveUser)?.name
    } else {
      board.aUser = '';
    }
    board.path.push(board.name);
  }

  for(let list of lists) {
    list.path = [];
    list.path.push(project.name);
    let board = selectors.selectBoardById(state ,list.boardId);
    if(board) {
      list.path.push(board.name);
      if(board.is_archive === true) {
        list.parent_is_archive = true;
        list.parent_path = 'доски ' + list.path.join(' → ');
      } else {
        list.parent_is_archive = false;
      }
    }

    if(list.archiveUser) {
      list.aUser = selectors.selectUserById(state, list.archiveUser)?.name
    } else {
      list.aUser = '';
    }
    list.path.push(list.name);
  }

  for(let card of cards) {
    card.path = [];
    let board = null;
    card.path.push(project.name);
    if(card.boardId) {
      board = selectors.selectBoardById(state ,card.boardId);
      if(board) {
        card.path.push(board.name);
        if(board.is_archive === true) {
          card.parent_is_archive = true;
          card.parent_path = 'доски ' + card.path.join(' → ');
        } else {
          card.parent_is_archive = false;
        }
      }
    }
    if(card.listId) {
      let list = selectors.selectListById(state ,card.listId);

      if(list) {
        card.path.push(list.name);
        if(list.is_archive === true) {
          card.parent_is_archive = true;
          card.parent_path = 'списка ' + card.path.join(' → ');
        } else {
          card.parent_is_archive = (board && board.is_archive) ? true : false;
        }
      }
    }
    if(card.archiveUser) {
      card.aUser = selectors.selectUserById(state, card.archiveUser)?.name
    } else {
      card.aUser = '';
    }
    card.path.push(card.name);
  }


  return {
    items: {
      boards: boards,
      cards: cards,
      lists: lists
    },
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onRestoreCard: entryActions.restoreCard,
      onDeleteCard: entryActions.deleteCard,
      onRestoreList: entryActions.restoreList,
      onDeleteList: entryActions.deleteList,
      onRestoreBoard: entryActions.restoreBoard,
      onDeleteBoard: entryActions.deleteBoard,
      onClose: entryActions.closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveModal);
