import ActionTypes from '../constants/ActionTypes';

const createList = (list) => ({
  type: ActionTypes.LIST_CREATE,
  payload: {
    list,
  },
});

createList.success = (localId, list) => ({
  type: ActionTypes.LIST_CREATE__SUCCESS,
  payload: {
    localId,
    list,
  },
});

createList.failure = (localId, error) => ({
  type: ActionTypes.LIST_CREATE__FAILURE,
  payload: {
    localId,
    error,
  },
});

const handleListCreate = (list) => ({
  type: ActionTypes.LIST_CREATE_HANDLE,
  payload: {
    list,
  },
});

const updateList = (id, data) => ({
  type: ActionTypes.LIST_UPDATE,
  payload: {
    id,
    data,
  },
});

updateList.success = (list) => ({
  type: ActionTypes.LIST_UPDATE__SUCCESS,
  payload: {
    list,
  },
});

updateList.failure = (id, error) => ({
  type: ActionTypes.LIST_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleListUpdate = (list) => ({
  type: ActionTypes.LIST_UPDATE_HANDLE,
  payload: {
    list,
  },
});

const deleteList = (id) => ({
  type: ActionTypes.LIST_DELETE,
  payload: {
    id,
  },
});

deleteList.success = (list) => ({
  type: ActionTypes.LIST_DELETE__SUCCESS,
  payload: {
    list,
  },
});

deleteList.failure = (id, error) => ({
  type: ActionTypes.LIST_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleListDelete = (list) => ({
  type: ActionTypes.LIST_DELETE_HANDLE,
  payload: {
    list,
  },
});

const archiveList = (id) => ({
  type: ActionTypes.LIST_ARCHIVE,
  payload: {
    id,
  },
});

archiveList.success = (list) => ({
  type: ActionTypes.LIST_ARCHIVE__SUCCESS,
  payload: {
    list,
  },
});

archiveList.failure = (id, error) => ({
  type: ActionTypes.LIST_ARCHIVE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleListArchive = (list) => ({
  type: ActionTypes.LIST_ARCHIVE_HANDLE,
  payload: {
    list,
  },
});

const restoreList = (id) => ({
  type: ActionTypes.LIST_RESTORE,
  payload: {
    id,
  },
});

restoreList.success = (list) => ({
  type: ActionTypes.LIST_RESTORE__SUCCESS,
  payload: {
    list,
  },
});

restoreList.failure = (id, error) => ({
  type: ActionTypes.LIST_RESTORE__FAILURE,
  payload: {
    id,
    error,
  },
});

const handleListRestore = (list) => ({
  type: ActionTypes.LIST_RESTORE_HANDLE,
  payload: {
    list,
  },
});

export default {
  createList,
  handleListCreate,
  updateList,
  handleListUpdate,
  deleteList,
  handleListDelete,
  archiveList,
  handleListArchive,
  restoreList,
  handleListRestore,
};
