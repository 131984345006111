import socket from './socket';

/* Actions */

const createList = (boardId, data, headers) =>
  socket.post(`/boards/${boardId}/lists`, data, headers);

const updateList = (id, data, headers) => socket.patch(`/lists/${id}`, data, headers);

const deleteList = (id, headers) => socket.delete(`/lists/${id}`, undefined, headers);

const archiveList = (id, headers) => 
  socket.patch(`/lists/${id}`, { is_archive: true }, headers).then((body) => ({
      ...body,
      item: body.item,
    }));

const restoreList = (id, headers) => 
  socket.patch(`/lists/${id}`, { is_archive: false }, headers).then((body) => ({
    ...body,
    item: body.item,
  }));
;


export default {
  createList,
  updateList,
  deleteList,
  archiveList,
  restoreList,
};
