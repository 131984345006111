import React from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { Popup } from '../../lib/custom-ui';
import { closePopup, usePopup } from '../../lib/popup';
import styles from './CloneStep.module.scss';





const CloneStep = React.memo(({ title, content, buttonContent, onConfirm, onBack }) => {
  const [t] = useTranslation();


  const handleSubmit = useCallback(() => {  
      onConfirm();
      
      closePopup();
    }, [onConfirm, onBack]);



  return (
    <>
      <Popup.Header onBack={onBack}>
        {t(title, {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
      <Form onSubmit={handleSubmit}>
        <div className={styles.content}>{t(content)}</div>
        <Button fluid positive content={t(buttonContent)}  />
      </Form>
      </Popup.Content>
    </>
  );
});
CloneStep.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonContent: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

CloneStep.defaultProps = {
  onBack: undefined,
};

export default CloneStep;
