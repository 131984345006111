import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import selectors from '../selectors';
import entryActions from '../entry-actions';
import { BoardMembershipRoles } from '../constants/Enums';
import Board from '../components/Board';

const mapStateToProps = (state) => {
  let { cardId } = selectors.selectPath(state);
  const currentUserMembership = selectors.selectCurrentUserMembershipForCurrentBoard(state);
  let listIds = selectors.selectListIdsForCurrentBoard(state);
  
  const currentUser = selectors.selectCurrentUser(state);
  const isSAdmin = currentUser.isSAdmin;
  const isCurrentUserEditor =
    !!currentUserMembership && currentUserMembership.role === BoardMembershipRoles.EDITOR;

  const boardData = selectors.selectCurrentBoard(state);
  if(boardData.is_archive) {
    listIds = [];
    cardId = null;
  }

  return {
    listIds,
    isCardModalOpened: !!cardId,
    canEdit: boardData.is_archive ? false : (isCurrentUserEditor || isSAdmin),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onListCreate: entryActions.createListInCurrentBoard,
      onListMove: entryActions.moveList,
      onCardMove: entryActions.moveCard,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Board);
