import pick from 'lodash/pick';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Radio, Table  } from 'semantic-ui-react';
import { useSteps } from '../../../hooks';
import { usePopup } from '../../../lib/popup';
import DeleteStep from '../../DeleteStep';
import InfoPopup from '../../InfoPopup';
import styles from './ActionsStep.module.scss';

const StepTypes = {
  RESTORE: 'RESTORE',
  DELETE: 'DELETE',
};

const ActionsStep = React.memo(
  ({
    parent_is_archive,
    parent_path,
    onDelete,
    onRestore,
  }) => {
    const [t] = useTranslation();
    let content = `Восстановление карточку станет доступно только после восстановления ${parent_path}`;
    const DeletePopup = usePopup(DeleteStep);
    const InfoPopupData = usePopup(InfoPopup);
    const [step, openStep, handleBack] = useSteps();
    const handleDeleteClick = useCallback(() => {
      openStep(StepTypes.DELETE);
    }, [openStep]);
    const handleRestoreClick = useCallback(() => {
      
      openStep(StepTypes.RESTORE);
    }, [openStep]);

    if (step) {
      switch (step.type) {
        case StepTypes.DELETE:
          return (
            <DeleteStep
              title="Окончательное удаление"
              content="Элемент будет удален безвозвратно. Удалить?"
              buttonContent="Удалить элемент"
              onConfirm={onDelete}
              onBack={handleBack}
            />
          );
        case StepTypes.RESTORE:
          return (
            <DeleteStep
              title="Восстановление"
              content="Восстановить элемент?"
              buttonContent="Восстановить"
              onConfirm={onRestore}
              onBack={handleBack}
            />
          );
        default:
      }
    }

    return parent_is_archive ? 
      (
        <>
          <div>
              <InfoPopupData
                title="Восстановить"
                content={content}
              >
                <Button  onClick={handleRestoreClick}>
                  {t('Восстановить', {
                    context: 'title',
                  })}
                </Button>
              </InfoPopupData>
              <DeletePopup
                title="Окончательное удаление"
                content="Элемент будет удален безвозвратно. Удалить?"
                buttonContent="Удалить элемент"
                onConfirm={onDelete}
                onBack={handleBack}
              >
                <Button negative onClick={handleDeleteClick}>
                  <Icon fitted name="trash" />
                </Button>
              </DeletePopup>
              
          </div>
        </>
      )
    : (
      <>
        <div>
            <DeletePopup
              title="Восстановление"
              content="Восстановить элемент?"
              buttonContent="Восстановить"
              onConfirm={onRestore}
            >
              <Button  onClick={handleRestoreClick}>
                {t('Восстановить', {
                  context: 'title',
                })}
              </Button>
            </DeletePopup>
            <DeletePopup
              title="Окончательное удаление"
              content="Элемент будет удален безвозвратно. Удалить?"
              buttonContent="Удалить элемент"
              onConfirm={onDelete}
              onBack={handleBack}
            >
              <Button negative onClick={handleDeleteClick}>
                <Icon fitted name="trash" />
              </Button>
            </DeletePopup>
            
        </div>
      </>
    );
  },
);

ActionsStep.propTypes = {
  parent_is_archive: PropTypes.bool,
  parent_path: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
};

export default ActionsStep;
