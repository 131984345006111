import { call } from 'redux-saga/effects';

import { fetchBoardByCurrentPath } from './boards';
import request from '../request';
import api from '../../../api';
import mergeRecords from '../../../utils/merge-records';
import { selectPath } from '../../../selectors/router';


export const transformCard = (card) => ({
  ...card,
  ...(card.dueDate && {
    dueDate: new Date(card.dueDate),
  }),
  ...(card.stopwatch && {
    stopwatch: {
      ...card.stopwatch,
      ...(card.stopwatch.startedAt && {
        startedAt: new Date(card.stopwatch.startedAt),
      }),
    },
  }),
});

export const transformCardData = (data) => ({
  ...data,
  ...(data.dueDate && {
    dueDate: data.dueDate.toISOString(),
  }),
  ...(data.stopwatch && {
    stopwatch: {
      ...data.stopwatch,
      ...(data.stopwatch.startedAt && {
        startedAt: data.stopwatch.startedAt.toISOString(),
      }),
    },
  }),
});

export function* fetchCore() {
  const { item: user } = yield call(request, api.getCurrentUser, true);
  const { items: users1 } = yield call(request, api.getUsers);
  let path_ = window.location.pathname.split('/');
  console.log(path_)
  //let projectId = yield call(selectPath());
  const {
    items: projects1,
    included: { projectManagers, cards,users , lists, boards, boardMemberships: boardMemberships1 },
  } = yield call(request, api.getProjects);

  let board;
  let card;
  let users2;
  let projects2;
  let boardMemberships2;
  let labels;
  //let lists;
  let cards1;
  let cardMemberships;
  let cardLabels;
  let tasks;
  let attachments;

  try {
    ({
      board,
      card,
      users: users2,
      projects: projects2,
      boardMemberships: boardMemberships2,
      labels,
      lists,
      cards: cards1,
      cardMemberships,
      cardLabels,
      tasks,
      attachments,
    } = yield call(fetchBoardByCurrentPath));
  } catch {} // eslint-disable-line no-empty

  const body = yield call(request, api.getNotifications);

  let { items: notifications } = body;

  const {
    included: { users: users3, cards: cards2, activities },
  } = body;

  if (card) {
    const notificationIds = notifications.flatMap((notification) =>
      notification.cardId === card.id ? [notification.id] : [],
    );

    if (notificationIds.length > 0) {
      yield call(request, api.updateNotifications, notificationIds, {
        isRead: true,
      });

      notifications = notifications.filter(
        (notification) => !notificationIds.includes(notification.id),
      );
    }
  }

  cards.forEach(card => {
    card.dueDate = card.dueDate ? new Date(card.dueDate) : null

  });

  return {
    user,
    board,
    projectManagers,
    boards,
    labels,
    lists,
    cardMemberships,
    cardLabels,
    tasks,
    attachments,
    activities,
    notifications,
    users: mergeRecords(users1, users2, users3),
    projects: mergeRecords(projects1, projects2),
    boardMemberships: mergeRecords(boardMemberships1, boardMemberships2),
    cards:cards,//mergeRecords(cards1, cards2),
  };
}

export default {
  fetchCore,
};
