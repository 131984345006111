import { createSelector } from 'redux-orm';

import orm from '../orm';
import { selectPath } from './router';
import { selectCurrentUserId, selectCurrentUser } from './users';
import { isLocalId } from '../utils/local-id';

export const selectCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  ({ Project }, id) => {
    
    if (!id) {
      return id;
    }
    
    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel.ref;
  },
);

export const selectManagersForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel
      .getOrderedManagersQuerySet()
      .toModelArray()
      .map((projectManagerModel) => ({
        ...projectManagerModel.ref,
        isPersisted: !isLocalId(projectManagerModel.id),
        user: {
          ...projectManagerModel.user.ref,
          isCurrent: projectManagerModel.user.id === currentUserId,
        },
      }));
  },
);

export const selectBoardsForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  (state) => selectCurrentUser(state),
  ({ Project, Board }, id, currentUserId, currentUser) => {
    if (!id) {
      return id;
    }
    
    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }
    if(currentUser.isSAdmin === true) {
      return Board.all().toModelArray().filter(v => !v.is_archive && v.projectId === projectModel.id).map((boardModel) => ({
        ...boardModel.ref,
        isPersisted: true,
      }));
    }
    return projectModel
      .getOrderedBoardsModelArrayAvailableForUser(currentUserId)
      .map((boardModel) => ({
        ...boardModel.ref,
        isPersisted: !isLocalId(boardModel.id),
      }));
  },
);

export const selectIsCurrentUserManagerForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return false;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return false;
    }

    return projectModel.hasManagerForUser(currentUserId);
  },
);

export default {
  selectCurrentProject,
  selectManagersForCurrentProject,
  selectBoardsForCurrentProject,
  selectIsCurrentUserManagerForCurrentProject,
};
