import { createSelector } from 'redux-orm';

import orm from '../orm';
import { isLocalId } from '../utils/local-id';
import { selectPath } from './router';
export const makeSelectListById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ List }, id) => {
      const listModel = List.withId(id);

      if (!listModel) {
        return listModel;
      }

      return {
        ...listModel.ref,
        isPersisted: !isLocalId(id),
      };
    },
  );

export const selectListById = makeSelectListById();

export const makeSelectCardIdsByListId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ List }, id) => {
      const listModel = List.withId(id);

      if (!listModel) {
        return listModel;
      }

      return listModel.getFilteredOrderedCardsModelArray().filter(v => v.is_archive != true).map((cardModel) => cardModel.id);
    },
  );

export const selectCardIdsByListId = makeSelectCardIdsByListId();

export const makeSelectArchivedLists = () => 
  createSelector(
    orm,
    (state) => selectPath(state).projectId,
    ({ List, Board }, projectId) => {
      let boardsIds = Board.all().filter(v => v.projectId == projectId).toModelArray().map(v => v.id);
      return List.all().filter(v => (v.is_archive == true && boardsIds.indexOf(v.boardId) > -1)).toModelArray().sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).map((v) => { 
        const date = new Date(v.updatedAt);
        date.setHours(date.getHours() + 3);
        return {...v.ref, updatedAt: date.toISOString().slice(0, 16).replace('T', ' ')} 
      });;
    }
  );

export const selectArchivedLists = makeSelectArchivedLists();


export const makeSelectArchivedCards = (state) => 
  createSelector(
    orm,
    (state) => selectPath(state).projectId,
    ({ Card, Board }, projectId) => {
      let boardsIds = Board.all().filter(v => v.projectId == projectId).toModelArray().map(v => v.id);
      return Card.all().filter(v => (v.is_archive == true && boardsIds.indexOf(v.boardId) > -1)).toModelArray().sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).map((v) => { 
        const date = new Date(v.updatedAt);
        date.setHours(date.getHours() + 3);
        return {...v.ref, updatedAt: date.toISOString().slice(0, 16).replace('T', ' ')} 
      });
    }
  );

export const selectArchivedCards = makeSelectArchivedCards();

export const makeSelectArchivedBoards = () => 
  createSelector(
    orm,
    (state) => selectPath(state).projectId,
    ({ Board }, id) => {
      return Board.all().filter(v => v.is_archive == true && v.projectId == id).toModelArray().sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).map((v) => { 
        const date = new Date(v.updatedAt);
        date.setHours(date.getHours() + 3);
        return {...v.ref, updatedAt: date.toISOString().slice(0, 16).replace('T', ' ')} 
      });;
    }
  );

export const selectArchivedBoards = makeSelectArchivedBoards();

export default {
  makeSelectListById,
  selectListById,
  selectArchivedLists,
  makeSelectArchivedLists,
  selectArchivedCards,
  makeSelectArchivedCards,
  selectArchivedBoards,
  makeSelectArchivedBoards,
  makeSelectCardIdsByListId,
  selectCardIdsByListId,

};
