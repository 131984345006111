import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Radio, Table } from 'semantic-ui-react';
import { usePopup } from '../../../lib/popup';

import ActionsStep from './ActionsStep';



import styles from './Item.module.scss';

const Item = React.memo(
  ({
    parent_is_archive,
    parent_path,
    name,
    path,
    user,
    updatedAt,
    onRestore,
    onDelete,
  }) => {

    //const ActionsPopup = usePopup(ActionsStep);

    return (
      <Table.Row className={styles.row}>
        <Table.Cell>{path[0] ? path[0] : ''}</Table.Cell>
        <Table.Cell>{path[1] ? path[1] : ''}</Table.Cell>
        <Table.Cell>{path[2] ? path[2] : ''}</Table.Cell>
        <Table.Cell>{path[3] ? path[3] : ''}</Table.Cell>
        <Table.Cell>{user}</Table.Cell>
        <Table.Cell>{updatedAt}</Table.Cell>
        <Table.Cell textAlign="right">
          <ActionsStep
            item={{
              name,
              
            }}
            parent_path={parent_path}
            parent_is_archive={parent_is_archive}
            onRestore={onRestore}
            onDelete={onDelete}
          >
          </ActionsStep>
        </Table.Cell>
      </Table.Row>
    );
  },
);

Item.propTypes = {
  parent_is_archive: PropTypes.bool,
  parent_path: PropTypes.string,
  name: PropTypes.string.isRequired,
  path: PropTypes.array.isRequired,
  user: PropTypes.string,
  updatedAt: PropTypes.string.isRequired,
  onRestore: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,

};

Item.defaultProps = {
  name:undefined
};

export default Item;
