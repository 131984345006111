import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table, Tab } from 'semantic-ui-react';
import { usePopup } from '../../lib/popup';
import Item from './Item';
import styles from './ArchiveModal.module.scss';

const ArchiveModal = React.memo(
  ({
    items,
    onRestoreCard,
    onDeleteCard,
    onRestoreList,
    onDeleteList,
    onRestoreBoard,
    onDeleteBoard,
    onClose,
  }) => {
    const [t] = useTranslation();
    const handleDeleteBoard = useCallback(
      (id) => {
        onDeleteBoard(id);
      },
      [onDeleteBoard],
    );
    
    const handleRestoreBoard = useCallback(
      (id) => {
        onRestoreBoard(id);
      },
      [onRestoreBoard],
    );


    const handleDeleteList = useCallback(
      (id) => {
        onDeleteList(id);
      },
      [onDeleteList],
    );

    const handleRestoreList = useCallback(
      (id) => {
        onRestoreList(id);
      },
      [onRestoreList],
    );

    const handleDeleteCard = useCallback(
      (id) => {
        onDeleteCard(id);
      },
      [onDeleteCard],
    );

    const handleRestoreCard = useCallback(
      (id) => {
        onRestoreCard(id);
      },
      [onRestoreCard],
    );

    const panes = [
          {
            menuItem: t('Доски', {
              context: 'title',
            }),
            render: items.boards.length > 0 ? () => (
              <Table unstackable basic="very" className={styles.archiveTable}>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={2}>{t('Проект')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Доска')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Список')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Карточка')}</Table.HeaderCell>
                    <Table.HeaderCell width={1} >{t('Кто удалил')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Дата и время')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.boards.map((item) => (
                    <Item
                      key={item.id}
                      parent_is_archive={item.parent_is_archive}
                      parent_path={item.parent_path}
                      name={item.name}
                      user={item.aUser}
                      path={item.path}
                      updatedAt={item.updatedAt}
                      onRestore= {() => handleRestoreBoard(item.id)}
                      onDelete={() => handleDeleteBoard(item.id)}
                    />
                  ))}
                </Table.Body>
              </Table>
            ): () => ( <div className={styles.emptyList}>Нет досок</div> ),
          },
          {
            menuItem: t('Списки', {
              context: 'title',
            }),
            render: items.lists.length > 0 ? () => (
              <Table unstackable basic="very" className={styles.archiveTable}>
                <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={2}>{t('Проект')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Доска')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Список')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Карточка')}</Table.HeaderCell>
                    <Table.HeaderCell width={1} >{t('Кто удалил')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Дата и время')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.lists.map((item) => (
                    <Item
                      key={item.id}
                      parent_is_archive={item.parent_is_archive}
                      parent_path={item.parent_path}
                      name={item.name}
                      path={item.path}
                      user={item.aUser}
                      updatedAt={item.updatedAt}
                      onRestore= {() => handleRestoreList(item.id)}
                      onDelete={() => handleDeleteList(item.id)}
                    />
                  ))}
                </Table.Body>
              </Table>
            ) : () => ( <div className={styles.emptyList}>Нет списков</div> ),
          },
          {
            menuItem: t('Карточки', {
              context: 'title',
            }),
            render: items.cards.length > 0 ? () => (
              <Table unstackable basic="very" className={styles.archiveTable}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={2}>{t('Проект')}</Table.HeaderCell>  
                    <Table.HeaderCell width={2}>{t('Доска')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Список')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Карточка')}</Table.HeaderCell>
                    <Table.HeaderCell width={1} >{t('Кто удалил')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('Дата и время')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {items.cards.map((item) => (
                    <Item
                      key={item.id}
                      parent_is_archive={item.parent_is_archive}
                      parent_path={item.parent_path}
                      name={item.name}
                      user={item.aUser}
                      path={item.path}
                      updatedAt={item.updatedAt}
                      onRestore= {() => handleRestoreCard(item.id)}
                      onDelete={() => handleDeleteCard(item.id)}
                    />
                  ))}
                </Table.Body>
              </Table>
            ) : () => ( <div className={styles.emptyList}>Нет карточек</div> ),
          }
        ]


    return (
      <Modal open closeIcon style={{ width: '90vw', maxWidth: '80%' }} centered={false} onClose={onClose}>
        <Modal.Header>
          {t('Архив', {
            context: 'title',
          })}
        </Modal.Header>
        <Modal.Content scrolling>
        <Tab
            menu={{
              secondary: true,
              pointing: true,
            }}
            panes={panes}
          />
        </Modal.Content>
      </Modal>
    );
  },
);

ArchiveModal.propTypes = {
  items: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onDeleteBoard: PropTypes.func.isRequired,
  onRestoreBoard: PropTypes.func.isRequired,
  onDeleteList: PropTypes.func.isRequired,
  onRestoreList: PropTypes.func.isRequired,
  onDeleteCard: PropTypes.func.isRequired,
  onRestoreCard: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ArchiveModal;
