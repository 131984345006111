import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { Popup } from '../../lib/custom-ui';

import styles from './InfoPopup.module.scss';

const InfoPopup = React.memo(({ title, content}) => {
  const [t] = useTranslation();

  return (
    <>
      <Popup.Header>
        {t(title, {
          context: 'title',
        })}
      </Popup.Header>
      <Popup.Content>
        <div className={styles.content}>{t(content)}</div>
      </Popup.Content>
    </>
  );
});

InfoPopup.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};


export default InfoPopup;
